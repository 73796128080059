.pastAnalysis {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.rootBody {
  height: 100%;
  overflow-y: auto;
  padding: 0.65rem;
}

.noEvent {
  margin-top: 2rem;
  width: 100%;
}
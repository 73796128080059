.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  // overflow: hidden;
  overflow-y: auto;
}

.contentBoard {
  flex-grow: 1;
  margin-top: 1.31rem;
  display: flex;
  overflow-y: auto;
}

.caseEventBody{
  height: 100%;
  overflow-y: auto;
  padding: 0.65rem;
}

.currentPage {
  flex-grow: 1;
  padding: 0.875rem 1.43rem 0.875rem 4.125rem;
  overflow-y: auto;
  height: 100%;
}

.reportPage{
  overflow-y: auto;
}

.noEvent {
  margin-top: 2rem;
  width: 100%;
}

.headingCheckboxRoot {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.125rem;
  cursor: pointer;
}

.headingCheckbox {
  margin-right: 1rem;

  & > div {
    background-color: white;
  }
}

.headingCheckboxTitle > div:first-child {
  margin-bottom: 0.3rem;
}

.reportBody{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.43rem 0.875rem 4.125rem;
}

.currentQuestionHeader {
  width: 100%;
  background-color: var(--color-gray7);
  padding: 1.5rem 2.375rem 1.25rem 2.375rem;
  margin-bottom: 1rem;
}

.currentQuestionHeader {
  margin-bottom: 1.5rem;
  & > div:first-child {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  & > div:last-child {
    font-size: 1.375rem;
  }
}
.wrapperOuter {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.wrapperHeader {
  height:  7.5rem;
}

.wrapperInner {
  display: flex;
  height: calc(100% - 7.5rem);
  flex-grow: 1;
}

.wrapperMenu {
  width: 18.3rem;
}

.wrapper {
  width: calc(100% - 18.3rem);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 5.625rem 0 3.375rem;
  overflow: hidden;
  overflow-y: auto;
}
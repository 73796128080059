.listWrapper {
  width: 100%;
  height: 100%;
  border-radius: 0.312rem;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  position: relative;
}

.listHeader {
  height: 3.625rem;
  min-height: 3.625rem;
  background-color: var(--color-gray6);
  font-size: 1.375rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-left: 1.18rem;
}

.listBody {
  flex-grow: 1;
  overflow-y: auto;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.43rem 0.625rem 1.19rem;
  cursor: pointer;

  & > div:first-child {
    flex-grow: 1;
    margin-right: 10px;
  }
}

.listItem:nth-child(even) {
  background-color: var(--color-whitegray);
}

.noItem {
  padding: 0.625rem 1.43rem 0.625rem 1.19rem;
  text-align: center;
  background-color: var(--color-whitegray);
  font-weight: 700;
}

.disabled {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-whitegray);
  opacity: 0.35;
}

.disabledItem {
  opacity: 0.6;
  cursor: default;
}

.loaderContainer {
  position: relative;
  height: 2.5rem;

  & > div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
.header {
  width: 100%;
  padding: 0 2.19rem;
  padding-top: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.headerLogo__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerLogo__svg {
  width: 11.68rem;
  height: 3.62rem;
  object-position: center;
}

.headerLogo__text {
  font-size: 1.44rem;
  font-weight: 700;
  color: var(--color-lightgray);
}

.headerStart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerStart__button {
  margin-left: 9.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  min-width: 12.12rem;
  height: 3.62rem;
  font-size: 1.25rem;
  font-weight: 800;
  text-align: center;
  border: 2px solid var(--color-darkblue);
  border-radius: 1.75rem;
  color: var(--color-darkblue);
  background-color: transparent;
  cursor: pointer;
}

.headerInfo {
  display: flex;
  align-items: center;
}

.headerText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.headerText__inner {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  color: var(--color-gray1);
}

.headerPharmaceutical {
  margin-left: 2.31rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userRole {
  font-size: 1rem;
  font-weight: 700;
  margin-left: 1rem;
  padding-top: 0.5rem;
  color: var(--color-darkblue)
}

.superUser {
  color: var(--color-lightblue);
}

.headerPharmaceutical__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerPharmaceutical__svg {
  width: 12.62rem;
  height: 3.81rem;
  object-position: center;
}


